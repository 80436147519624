import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../../styles/core.css"
import "../../../styles/styles.css"
import Layout from "../../../components/Layout"
import MenuOne from "../../../components/MenuOne"
import MenuTwo from "../../../components/MenuTwo"
import MenuThree from "../../../components/MenuThree"
import Content from "../../../components/Content"
import Gallery from "../../../components/Gallery"

const galImgs = ['/page-images/braniel-3540.jpg', '/page-images/house_1.jpg', '/page-images/braniel-3603.jpg', '/page-images/house4_0.jpg', '/page-images/crockard_1.jpg', '/page-images/hilary-mckee_3.jpg', '/page-images/deecontemp_1.jpg', '/page-images/templeburn_road_house_extension_0.jpg', '/page-images/braniel-3582_0.jpg']

export default function OurWork() {
  return (
    <Layout breadcrumbs={["Residential"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

<Content />

      <div class="region region-content-bottom">
        <MenuOne active='our-work' />
        <MenuTwo
          active=""
          items={[
            '/our-work/residential',
            '/our-work/commercial',
            '/our-work/industrial',
            '/our-work/leisure',
            '/our-work/community',
            '/our-work/retail',
          ]} />
        <MenuThree
          items={[
            '/our-work/residential/houses',
            '/our-work/residential/extensions',
            '/our-work/residential/apartments',
            '/our-work/residential/developments',
          ]} />
      </div>

  </Layout>

  )
}
